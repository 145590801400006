<template>
    <div v-bind="$attrs" v-on="$listeners">
        <template v-if="code != null">
            <template v-for="(item, index) in brandDicts">
                <span :style="{color:color}" v-bind:key="index" v-if="item.code == code || item.code == code.toString()">{{ item.name }} <slot></slot></span>
            </template>
        </template>
    </div>
</template>

<script>
// brandDicts: [];
export default {
    model: {
        prop: 'code'
    },
    props: {
		color:{
			type:String,
			default:'black'
		},
        code: {
            type: String | Boolean
        },
        codeType: {
            type: String
        }
    },
    data() {
        return {
            //字典下拉框
            brandDicts: []
        };
    },
    created() {
        this.getDictItems();
    },
    methods: {
        getDictItems() {
            var _this = this;
            this.$store.dispatch('getDicItems', { tag: this.codeType }).then(res => {
                if (res != null) {
                    _this.brandDicts = res;
                }
            });
        },
        getDictValue(tag, code) {
            var dict = this.brandDicts;
            if (dict == undefined || dict == null || dict.length == 0) {
                return code;
            }
            var code = dict.find(x => x.code == code);
            if (code) {
                return code.name;
            }
        }
    }
};
</script>

<style></style>
