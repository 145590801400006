var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("filter-container", {
        ref: "formTableDialog",
        attrs: {
          arg: _vm.argFrom,
          placeholder: _vm.placeholderFrom,
          dictionary: _vm.dictionary,
          isShowObject: _vm.isShowObjectFrom
        },
        on: {
          handleExport: _vm.handleExport,
          handleSearchList: _vm.handleSearchList,
          handleResetSearch: _vm.handleResetSearch,
          handleChangeCategory: _vm.handleChangeCategory
        }
      }),
      _vm._t("tableSlot"),
      _vm.closeFooter == false
        ? _c(
            "span",
            {
              staticStyle: {
                display: "flex",
                "justify-content": "flex-end",
                "align-items": "center"
              },
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _vm.typeName != "分销"
                ? _c("span", [
                    _vm._v(_vm._s(_vm.$t("common.Selected")) + " : "),
                    _c(
                      "span",
                      {
                        staticStyle: {
                          color: "#008000",
                          "font-size": "16px",
                          "font-weight": "800"
                        }
                      },
                      [_vm._v(_vm._s(_vm.pageDataSelectList.length))]
                    )
                  ])
                : _vm._e(),
              _c(
                "el-button",
                {
                  staticStyle: { width: "20%", "margin-left": "50px" },
                  attrs: { type: "primary" },
                  on: { click: _vm.handleSelected }
                },
                [_vm._v(_vm._s(_vm.$i18n.t("common.confirm")) + "\n\t\t")]
              )
            ],
            1
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }