import Vue from 'vue'

// 全选按钮触发事件
export function selectAll(selection, tableRefs, tableList, rowId='goodsId', childrenField = 'packageQtyList') {
    // 全选
    const isSelect = selection.some(item => {
        const tableDataIds = tableList.map(j => j[rowId])
        return tableDataIds.includes(item[rowId])
    })
    // 全不选
    const isCancel = !tableList.every(item => {
        const selectIds = selection.map(j => j[rowId])
        return selectIds.includes(item[rowId])
    })

    if (isSelect) {
        selection.map(item => {
            if (item[childrenField] && item[childrenField].length > 0) {
                // 解决子组件没有被勾选到
                setChildrenCheckbox(tableRefs, item[childrenField], true)
            }
        })
    }
    if (isCancel) {
        tableList.map(item => {
            if (item[childrenField] && item[childrenField].length > 0) {
                // 解决子组件没有被勾选到
                setChildrenCheckbox(tableRefs, item[childrenField], false)
            }
        })
    }
}

// 点击复选框触发事件
export function select(selection, tableRefs, tableList, rowId = 'goodsId', childrenField = 'packageQtyList') {
    setTimeout(() => {
        setSelectAllCheckbox(tableRefs, tableList);
    }, 100)
}

// 设置全选按钮
export function setSelectAllCheckbox(tableRefs, tableList, rowId = 'goodsId', childrenField = 'packageQtyList') {
    let selections = tableRefs.selection;

    // 获取全选按钮元素
    const element = document.querySelector('#goodsTable thead th span:first-child');

    let selectionIds = selections.map(item => item[rowId]);

    // 未选择
    if (!selectionIds || selectionIds.length == 0) {
        Vue.nextTick(() =>{
            element.className = 'el-checkbox__input'
        })
        return;
    }

    let ids = [];
    tableList.forEach(item => {
        ids.push(item[rowId])
        if(item[childrenField] && item[childrenField].length > 0) {
            item[childrenField].forEach(item2 => {
                ids.push(item2[rowId])
            })
        }
    })

    const bo = ids.every(id => selectionIds.includes(id));
    if (bo) {
        // 全选
        Vue.nextTick(() =>{
            element.className = 'el-checkbox__input is-checked'
        })
    } else {
        // 部分选择
        Vue.nextTick(() =>{
            element.className = 'el-checkbox__input is-indeterminate'
        })
    }
}

//回显复选框勾选
export function showChildrenCheckbox(tableRefs, tableList, rowId = 'goodsId', childrenField = 'packageQtyList') {

    //解决子表格回显问题
    let allSelection = tableRefs.selection;

    //将单页数据封装成一个Map
    let goodsMap =  new Map();
    tableList.forEach((item)=>{
        goodsMap.set(item[rowId], item)
        if (item[childrenField] && item[childrenField].length > 0) {
            item[childrenField].forEach(item2 => {
                goodsMap.set(item2[rowId], item2)
            })
        }
    })

    //对单页子表格数据回显做重新设置，不然显示不出来
    allSelection.forEach(selection => {
        //只由子表格才重置回显
        if (selection.quantityType) {
            let goods = goodsMap.get(selection[rowId]);
            if (goods) {
                toggleSelection(tableRefs, selection, false)
                toggleSelection(tableRefs, goods, true)
            }
        }
    })
    setTimeout(() => {
        setSelectAllCheckbox(tableRefs, tableList);
    }, 100)
}

//注意：表格有 selectable 使用 OfSelectable方法

let selectAllStatus = false;

// 全选按钮触发事件
export function selectAllOfSelectable(selection, tableRefs, tableList, rowId='goodsId', childrenField = 'packageQtyList') {

    selectAllStatus = !selectAllStatus;

    if (selectAllStatus) {
        tableList.map(item => {
            if (item.checkboxStatus) {
                toggleSelection(tableRefs, item, true);
            }
            if (item[childrenField] && item[childrenField].length > 0) {
                // 解决子组件没有被勾选到
                setChildrenCheckbox(tableRefs, item[childrenField], true)
            }
        })
    }
    if (!selectAllStatus) {
        tableList.map(item => {
            if (item.checkboxStatus) {
                toggleSelection(tableRefs, item, false);
            }
            if (item[childrenField] && item[childrenField].length > 0) {
                // 解决子组件没有被勾选到
                setChildrenCheckbox(tableRefs, item[childrenField], false)
            }
        })
    }
    setTimeout(() => {
        setSelectAllCheckboxOfSelectable(tableRefs, tableList)
    }, 100)
}

// 点击复选框触发事件
export function selectOfSelectable(selection, tableRefs, tableList, rowId = 'goodsId', childrenField = 'packageQtyList') {
    setTimeout(() => {
        setSelectAllCheckboxOfSelectable(tableRefs, tableList)
    }, 100)
}

// 回显勾选
export function showSelectOfSelectable(tableRefs, tableList, rowId = 'goodsId', childrenField = 'packageQtyList') {

    const goodsIds = tableRefs.selection.map(item => item[rowId])

    let goodsMap =  new Map();
    tableRefs.selection.forEach((item)=>{
        goodsMap.set(item[rowId], item)
        if (item[childrenField] && item[childrenField].length > 0) {
            item[childrenField].forEach(item2 => {
                goodsMap.set(item2[rowId], item2)
            })
        }
    })

    tableList.forEach(item => {
        if (goodsIds.includes(item[rowId]) && item.checkboxStatus) {
            let oldSelection = goodsMap.get(item[rowId])
            toggleSelection(tableRefs, oldSelection, false)
            toggleSelection(tableRefs, item, true)
        }
        if (item[childrenField] && item[childrenField].length > 0) {
            item[childrenField].forEach(j => {
                if (goodsIds.includes(j[rowId])) {
                    let oldSelection = goodsMap.get(j[rowId])
                    toggleSelection(tableRefs, oldSelection, false)
                    toggleSelection(tableRefs, j, true)
                }
            })
        }
    })
    setTimeout(() => {
        setSelectAllCheckboxOfSelectable(tableRefs, tableList);
    }, 100)
}

// 设置全选按钮
export function setSelectAllCheckboxOfSelectable(tableRefs, tableList, rowId = 'goodsId', childrenField = 'packageQtyList') {

    let selections = tableRefs.selection;

    // 获取全选按钮元素
    const element = document.querySelector('#goodsTable thead th span:first-child');

    let selectionIds = selections.map(item => item[rowId]);

    // 未选择
    if (!selectionIds || selectionIds.length == 0) {
        selectAllStatus = false
        Vue.nextTick(() =>{
            element.className = 'el-checkbox__input'
        })
        return;
    }

    let ids = [];
    tableList.forEach(item => {
        if (item.checkboxStatus) {
            ids.push(item[rowId])
        }
        if(item[childrenField] && item[childrenField].length > 0) {
            item[childrenField].forEach(item2 => {
                ids.push(item2[rowId])
            })
        }
    })

    const bo = ids.every(id => selectionIds.includes(id));

    if (bo) {
        // 全选
        selectAllStatus = true
        Vue.nextTick(() =>{
            element.className = 'el-checkbox__input is-checked'
        })
    } else {
        // 部分选择
        selectAllStatus = false
        Vue.nextTick(() =>{
            element.className = 'el-checkbox__input is-indeterminate'
        })
    }
}

// 设置子表格复选框状态
function setChildrenCheckbox(tableRefs, children, status) {
    // 编辑多个子层级
    children.forEach(j => {
        toggleSelection(tableRefs, j, status)
    })
}

// 设置复选框状态
function toggleSelection(tableRefs, row, status) {
    if (row) {
        Vue.nextTick(() => {
            tableRefs.toggleRowSelection(row, status)
        })
    }
}