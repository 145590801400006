var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._g(_vm._b({}, "div", _vm.$attrs, false), _vm.$listeners),
    [
      _vm.code != null
        ? [
            _vm._l(_vm.brandDicts, function(item, index) {
              return [
                item.code == _vm.code || item.code == _vm.code.toString()
                  ? _c(
                      "span",
                      { key: index, style: { color: _vm.color } },
                      [_vm._v(_vm._s(item.name) + " "), _vm._t("default")],
                      2
                    )
                  : _vm._e()
              ]
            })
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }