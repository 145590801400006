const NEW_USER_TYPE = 'NEW';
const OLD_USER_TYPE = 'OLD';

//是否显示促销
export function isShowGoodsPromotion(promotionRuleCountrys, userType) {
    if (!promotionRuleCountrys) {
        return false;
    }
    if (userType == NEW_USER_TYPE) {
        return promotionRuleCountrys.some(item => item.userType == NEW_USER_TYPE);
    } else {
        return promotionRuleCountrys.some(item => item.userType == OLD_USER_TYPE);
    }
}

//获取促销价格
export function getPromotionGoodsPrice(promotion, prices) {
    const resultPrice = getResultPrice(promotion, prices);
    if (promotion.promotionType == 'Price') {
        return getPromotionPrice(promotion, resultPrice);
    } else {
        return getPromotionDiscountPrice(promotion, resultPrice);
    }
}

//获取促销类型为价格的价格
function getPromotionPrice(promotion, resultPrice) {
    if (promotion.promotionValue >= resultPrice) {
        return resultPrice.toFixed(2);
    }
    return promotion.promotionValue.toFixed(2);
}

//获取促销类型为折扣的价格
function getPromotionDiscountPrice(promotion, resultPrice) {
    let promotionValue = promotion.promotionValue.toFixed(1);
    if (promotionValue > 100) {
        promotionValue = 100;
    }

    return (resultPrice - (promotionValue / 100) * resultPrice).toFixed(2);
}

//获取价格
function getResultPrice(promotion, prices) {
    let resultPrice = null;
    prices.forEach(item => {
        if (promotion.countryCode == item.countryCode) {
            let computePrice = null;
            if (item.fixedPrice) {
                computePrice = item.fixedPrice;
            } else if (item.salePriceEbp) {
                computePrice = item.salePriceEbp;
            } else if (item.resultPrice) {
                computePrice = item.resultPrice;
            }
            resultPrice = computePrice;
        }
    });
    return resultPrice;
}

//促销国家（去重）
export function distinctPromotionCountry(promotionRules) {
    if (!promotionRules || promotionRules.length == 0) {
        return [];
    }
    const map = promotionRules.reduce((acc, item) => {
        acc.set(item.countryCode, item);
        return acc;
    }, new Map());
    return map.values();
}

//实际促销比例
//实际剩余/总数
export function actualPromotionProportion(promotionRule, goodsSpecials) {
    const quantity = getGoodsQuantity(promotionRule, goodsSpecials);
    if (!quantity) {
        return;
    }
    const actualSales = promotionRule.salesCount <= 0 ? 0 : Math.floor(promotionRule.salesCount / quantity);
    const actualTotal = promotionRule.promotionCount <= 0 ? 0 : Math.floor(promotionRule.promotionCount / quantity);
    //剩余
    const actualResidue = actualTotal - actualSales;
    return actualResidue + "/" + actualTotal;
}

//总促销比例
// 【(实际剩余/数量装）+（虚拟剩余/数量装）】/【（促销数量/数量装）+（虚拟数量/数量装）】
export function totalPromotionProportion(promotionRule, goodsSpecials) {
    const quantity = getGoodsQuantity(promotionRule, goodsSpecials);
    if (!quantity) {
        return;
    }
    const actualSales = promotionRule.salesCount <= 0 ? 0 : Math.floor(promotionRule.salesCount / quantity);
    //通过时间计算
    const virtualSalesNum = calVirtualSales(promotionRule, false);
    const virtualSales = virtualSalesNum == 0 ? 0 : Math.floor(virtualSalesNum / quantity);
    const actualTotal = promotionRule.promotionCount == 0 ? 0 : Math.floor(promotionRule.promotionCount / quantity);
    const virtualQtyTotal = promotionRule.virtualQty == 0 ? 0 : Math.floor(promotionRule.virtualQty / quantity);
    //剩余销量
    const residueSales = (actualTotal + virtualQtyTotal) - (actualSales + virtualSales)
    return residueSales + "/" + (actualTotal + virtualQtyTotal);
}

//获取商品销售倍数
export function getGoodsQuantity(promotionRule, goodsSpecials) {
    if (!goodsSpecials || goodsSpecials.length == 0) {
        return 0;
    }
    let quantity;
    goodsSpecials.forEach(special => {
        if (promotionRule.countryCode == special.countryCode) {
            quantity = special.saleDoubleEbp;
        }
    });
    return quantity;
}

//计算虚拟销售数量
export function calVirtualSales(promotionRule, edit = false) {
    let beginTime;
    let endTime;
    //是否编辑(编辑和列表回显取的时间值不一样)
    if (edit) {
        //编辑需要取时间组件变量中的值
        if (!promotionRule.time || promotionRule.time.length == 0) {
            return 0;
        }
        beginTime = new Date(promotionRule.time[0]).getTime();
        endTime = new Date(promotionRule.time[1]).getTime();
    } else {
        if (!promotionRule.beginTime || !promotionRule.endTime) {
            return 0;
        }
        beginTime = promotionRule.beginTime;
        endTime = promotionRule.endTime;
    }
    let promotionCount = promotionRule.promotionCount;
    let salesCount = promotionRule.salesCount;
    let virtualQty = promotionRule.virtualQty;
    let soldOut = promotionRule.soldOut;
    //还未开始
    const nowTime = new Date().getTime();
    if (nowTime <= beginTime || !virtualQty) {
        return 0;
    }
    if (nowTime >= endTime) {
        return Number(virtualQty);
    }
    //售罄全部释放虚拟数量的剩余部分
    if (soldOut) {
        return Number(virtualQty);
    }
    //当实际数量≤每单限购时全部释放虚拟数量的剩余部分
    let actualResidueSales = promotionCount - salesCount
    if (actualResidueSales <= promotionRule.orderRestrictions) {
        return Number(virtualQty);
    }
    //总分钟数
    const totalMinute = (endTime- beginTime) / (60 * 1000);
    //过去分钟数
    const passedMinute = (nowTime - beginTime) / (60 * 1000);
    return Math.floor(virtualQty * passedMinute / totalMinute);
}
// 促销限制单笔订单value
export function handleOrderPurchaseLimit(items) {
    if (items.orderRestrictions != null && items.userRestrictions != null &&
        Number(items.userRestrictions) != 0 && Number(items.orderRestrictions) != 0 && Number(items.orderRestrictions) > Number(items.userRestrictions)) {
        items.orderRestrictions = items.userRestrictions;
    } else if (items.orderRestrictions != null && items.userRestrictions != null &&
        Number(items.userRestrictions) > 0  && Number(items.userRestrictions) < 5 && Number(items.orderRestrictions) == 0) {
        items.orderRestrictions = items.userRestrictions;
    }  else if (items.orderRestrictions != null && items.userRestrictions != null &&
        (Number(items.userRestrictions) >= 5 || Number(items.userRestrictions) == 0) && items.orderRestrictions == 0) {
        items.orderRestrictions = 5;
    }
}
// 根据价格优先级获取价格
export function getComputePrice(price){
    let computePrice = null;
    if (price.fixedPrice) {
        computePrice = price.fixedPrice
    } else if (price.salePriceEbp) {
        computePrice = price.salePriceEbp
    } else if (price.resultPrice) {
        computePrice = price.resultPrice
    }
    return computePrice
}