<template>
	<div>
		<el-tabs v-model="active_Name" @tab-click="handleActiveClick">
			<el-tab-pane :label='$i18n.t("common.Basicinformation")' name="1">
				<div class="descriptions">
					<el-descriptions :column="3">
						<el-descriptions-item   :label='$i18n.t("common.ProductID1")'>{{ formData.goodsId }}</el-descriptions-item>
						<el-descriptions-item label="SKU">
							<span>{{ formData.sku }}</span>
						</el-descriptions-item>
						<el-descriptions-item label="EAN">{{ formData.barCode }}</el-descriptions-item>

						<el-descriptions-item  :label='$i18n.t("common.number")'>{{ formData.goodsCode }}</el-descriptions-item>
            <!--
						<el-descriptions-item label="品牌"><span
								v-if="formData.treeList && formData.treeList.length > 0"><span
									v-if="formData.treeList[0].brandCode">{{ formData.treeList[0].brandCode
									}}</span></span></el-descriptions-item>
						-->
            <el-descriptions-item :label="$t('common.Numberofitems')">{{ formData.amount }}</el-descriptions-item>
            <el-descriptions-item :label='$i18n.t("common.Creationtime")'>{{ formData.createTime | formatDate }}</el-descriptions-item>
						<el-descriptions-item :label='$i18n.t("common.Sizeinformation")'>{{ formData.goodsSize1 }}</el-descriptions-item>
						<el-descriptions-item :label='$i18n.t("common.weight")'>

							<div style="display: flex;justify-content: flex-start;align-items: center;">
								<el-input disabled type="number" class="inputNumber"
									oninput="if(value>1000)value=1000;if(value<0)value=0;if(value.length>13)value=value.slice(0,13)value = value.replace(/^\D*([0-9]\d*\.?\d{0,5})?.*$/,'$1')"
									show-word-limit v-model="formListQuery.peso" @input="handleInput" :placeholder="$t('common.PleaseEnterTheWeight')"
									size="mini"></el-input>
								<div style="width: 20px;margin-left: 5px;">kg</div>
							</div>
						</el-descriptions-item>
						<el-descriptions-item :label='$i18n.t("common.volume")'>{{ formData.cbm }}</el-descriptions-item>
						<el-descriptions-item :label='$i18n.t("common.Salesmultiples")'>
							<span v-for="(item, index) in formData.goodsSpecials" :key="index">{{
								$dict.getDictValue('countrys', item.countryCode) }}/{{ item.saleDoubleEbpNew }}</span>
						</el-descriptions-item>

						<el-descriptions-item :label='$i18n.t("common.Numberofitems")'>{{ formData.amount }}</el-descriptions-item>

						<el-descriptions-item :label="$t('common.PackingQuantity')">{{ formData.packageQuantity }}</el-descriptions-item>

					</el-descriptions>
				</div>
				<div class="from-item">
					{{$i18n.t("common.country")}}:
				</div>
				<div class="from-item">
					<el-checkbox-group v-model="formListQuery.countryCodes">
						<el-checkbox v-for="(item, index) in dictionary.country" :key="item.code" :label="item.code">{{
							item.name }}</el-checkbox>
					</el-checkbox-group>
				</div>
				<div class="from-item">
					{{$i18n.t("common.Category")}}:
				</div>
				<el-tree ref="tree" :props="defaultProps" :data="dictionary.classifyTree" node-key="goodsClassifyId"
					:default-checked-keys="checkedKeys" show-checkbox>
				</el-tree>
			</el-tab-pane>

				<el-tab-pane :label='$i18n.t("common.inventory")' name="2">
				<el-button style="margin-bottom: 10px" type="primary" @click="openStockLimitDialog">{{ scene.isNonSelf?$t('common.edit'):$t('common.EditBottomLineInventory') }}</el-button>

				<el-table ref="pagedata" :data="formData.goodsSpecials" border style="width: 100%">
					<el-table-column prop="countryCode" :label='$i18n.t("common.country")' align="center" show-overflow-tooltip>
						<template slot-scope="scope">
							<span> {{ $dict.getDictValue('countrys', scope.row.countryCode) }} </span>
						</template>
					</el-table-column>
					<el-table-column prop="ecomRemainQty" :label='$i18n.t("common.inventory")' align="center" show-overflow-tooltip>
						<template slot-scope="scope">
							<span> {{ scope.row.ecomRemainQty?scope.row.ecomRemainQty:0 }} </span>
						</template>
					</el-table-column>

					<el-table-column prop="stockLimit" :label="$t('common.Inventorybottomline')" align="center" show-overflow-tooltip>
						<template slot-scope="scope">
							<span v-show="scope.row.stockLimit >= 0 && !isStockLimit"> {{ scope.row.stockLimit }} </span>
						</template>
					</el-table-column>
				</el-table>
			</el-tab-pane>


      <!--
			<el-tab-pane :label='$i18n.t("common.price")'  name="3">
				<el-button style="margin-bottom: 10px" type="primary" @click="openFixedPrice">{{$i18n.t("common.EditFixedPrice")}}</el-button>

				<el-table ref="pagedata" :data="formData.prices" border style="width: 100%">
					<el-table-column prop="countryCode" :label='$i18n.t("common.country")' align="center" show-overflow-tooltip>
						<template slot-scope="scope">
							<span> {{ $dict.getDictValue('countrys', scope.row.countryCode) }} </span>
						</template>
					</el-table-column>
					<el-table-column prop="lowestPrice" :label='$i18n.t("common.Lowestprice")' align="center" show-overflow-tooltip>
						<template slot-scope="scope">
							<span v-if="scope.row.fixedPrice>=0"> {{ Number(scope.row.lowestPrice).toFixed(2) }} </span>
						</template>
					</el-table-column>
					<el-table-column prop="EComRemainQty" :label='$i18n.t("common.Fixedprice1")' align="center" show-overflow-tooltip>
						<template slot-scope="scope">
							<span v-if="scope.row.fixedPrice>=0"> {{ Number(scope.row.fixedPrice).toFixed(2) }} </span>
						</template>
					</el-table-column>

					<el-table-column prop="EComRemainQty" :label='$i18n.t("common.Pricing_unitprice")' align="center" show-overflow-tooltip></el-table-column>

                    <el-table-column label="固定价格毛利率" align="center" show-overflow-tooltip>
                        <template slot-scope="scope">
                            <span v-if="scope.row.fixedPriceProfit>=0"> {{ Number(scope.row.fixedPriceProfit).toFixed(2) }} </span>
                        </template>
                    </el-table-column>
					<el-table-column prop="EComRemainQty" label="定价（单价）" align="center" show-overflow-tooltip>

						<template slot-scope="scope">
							<span v-if="scope.row.resultPrice"> {{ Number(scope.row.resultPrice).toFixed(2) }} </span>
						</template>
					</el-table-column>
					<el-table-column prop="EComRemainQty" :label='$i18n.t("common.price1")' align="center" show-overflow-tooltip>
						<template slot-scope="scope">
							<span v-if="scope.row.salePriceEbp || scope.row.salePriceEbp >= 0">
								{{ Number(scope.row.salePriceEbp).toFixed(2) }} </span>
						</template>
					</el-table-column>

                    <el-table-column label="售价毛利率" align="center" show-overflow-tooltip>
                        <template slot-scope="scope">
							<span v-if="scope.row.salePriceEbpProfit || scope.row.salePriceEbpProfit >= 0">
								{{ Number(scope.row.salePriceEbpProfit).toFixed(2) }} </span>
                        </template>
                    </el-table-column>
					<el-table-column prop="EComRemainQty" :label='$i18n.t("common.Promotionprice_unitprice")' align="center" show-overflow-tooltip>

						<template slot-scope="scope">
							<span v-if="scope.row.promotionPrice"> {{ Number(scope.row.promotionPrice).toFixed(2) }} </span>
						</template>
					</el-table-column>
                    <el-table-column label="促销价毛利率" align="center" show-overflow-tooltip>
                        <template slot-scope="scope">
                            <span v-if="scope.row.promotionPriceProfit"> {{ Number(scope.row.promotionPriceProfit).toFixed(2) }} </span>
                        </template>
                    </el-table-column>
				</el-table>
			</el-tab-pane>

      -->

      

			<!-- <el-tab-pane label="库存底线" name="4"> -->
				<!-- <el-button type="primary" size="mini" v-if="currentTenant!=tenant_idMall" @click="isStockLimit = !isStockLimit">编辑</el-button> -->
			<!-- <el-table ref="pagedata" :data="goodsSpecials" border style="width: 100%;margin-top: 20px;">
        <el-table-column prop="countryCode" label="国家" align="center" show-overflow-tooltip>
          <template slot-scope="scope">
            <span> {{ $dict.getDictValue('countrys', scope.row.countryCode) }} </span>
          </template>
        </el-table-column>
        <el-table-column prop="stockLimit" label="库存底线" align="center" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-input v-show="scope.row.stockLimit >= 0 && isStockLimit" v-model="scope.row.stockLimit"
              oninput="value=value.replace(/\D/g,'')" placeholder="请输入内容"></el-input>
            <span v-show="scope.row.stockLimit >= 0 && !isStockLimit"> {{ scope.row.stockLimit }} </span>
          </template>
        </el-table-column>
      </el-table>

      <div style="margin-top:20px" v-show="isStockLimit">
        同步到其他租户:
        <el-checkbox-group v-model="curTenantList">
          <el-checkbox v-for="item in tenantList" :key="item.tenant_id" :label="item.tenant_id">{{ item.name
          }}
          </el-checkbox>
        </el-checkbox-group>
      </div>

    </el-tab-pane>  -->
  </el-tabs>
  <span slot="footer" class="dialog-footer">
    <el-button style="width: 20%;" @click="closeDialog">{{$t('common.Cancel')}}</el-button>
    <el-button style="width: 20%;" type="primary" @click="onSubmit">{{$t('common.Confirm')}}</el-button>
  </span>
  <el-dialog title="" :visible.sync="dialogfixedPriceVisible" v-if="dialogfixedPriceVisible" width="30%"
    append-to-body :before-close="fixedPriceClose">
    <div>
      <el-form ref="titleFrom" label-width="100px">
        <el-form-item v-for="item in formData.prices" :key="item.countryCode"
          :label="$dict.getDictValue('countrys', item.countryCode)">
          <el-input v-model="item.fixedPrice" @input="check_num(item)" @blur="check_blurNum(item)"
            :placeholder="$t('common.PleaseEnterThePrice')"></el-input>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="fixedPriceClose">{{$t('common.Cancel')}}</el-button>
        <el-button type="primary" :loading="fixedPriceLoading" @click="postFixedPrice">{{$t('common.Confirm')}}</el-button>
      </span>
    </div>
  </el-dialog>
  <el-dialog :title="$t('common.Prompt')" :visible.sync="dialogLowestPriceVisible" width="20%" append-to-body
    :before-close="handleLowestPriceClose">
    <div v-if="dialogLowestPriceVisible">
      <h4><span>{{$t('common.FixedPriceCannotBeLowerThanTheLowestPrice')}}：</span></h4>
      <div style="width: 100%; display: flex; justify-content: center; align-items: center">
        <div style="width: 80%">
          <div v-for="(item, index) in checkLowestPrice" :key="index" style="margin: 10px 10px">
            {{ $dict.getDictValue('countrys', item.countryCode) }}:<span style="color: red">{{
              item.price.toFixed(2) }}</span>
          </div>
        </div>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="updateLowestPrice">{{$t('common.Confirm')}}</el-button>
      </span>
    </div>
  </el-dialog>

  <el-dialog
      :title="scene.isNonSelf ? $t('common.edit') : $t('common.BottomInventory')"
      :visible.sync="dialogStockLimitVisible"
			append-to-body
			width="30%"
      :before-close="closeStockLimitDialog">
   			 <div v-if="dialogStockLimitVisible">
				<el-form ref="stockLimitForm" label-width="120px">
					<div v-if="scene.isNonSelf">
						<el-table :data="goodsSpecials" class="stock-table">
							<el-table-column :label="$t('common.country')" align="center" prop="countryCode">
								<template slot-scope="scope">
									<span>{{$dict.getDictValue('countrys', scope.row.countryCode)}}</span>
								</template>
							</el-table-column>

							<el-table-column :label="$t('common.inventory')" align="center" prop="ecomRemainQty">
								<template slot-scope="scope" >
									<el-input style="width: 80px;" v-model="scope.row.ecomRemainQty" placeholder="请输入库存" oninput="value=value.replace(/\D/g,'')" @blur="tableInputBlur(scope.row)"/>
								</template>
							</el-table-column>

							<el-table-column :label="$t('common.Inventorybottomline')" align="center" prop="stockLimit">
								<template slot-scope="scope">
									<el-input style="width: 80px;" v-model="scope.row.stockLimit" placeholder="请输入底线库存" oninput="value=value.replace(/\D/g,'')" @blur="tableInputBlur(scope.row)"/>
								</template>
							</el-table-column>
						</el-table>
					</div>
					<div v-else>
						<el-form-item v-for="item in goodsSpecials" :key="item.countryCode"
													:label="$dict.getDictValue('countrys', item.countryCode)">
<!--							<el-input :readonly="!item.ecomRemainQty || item.stockLimitEbp <= item.ecomRemainQty" v-model="item.stockLimit" oninput="value=value.replace(/\D/g,'')" placeholder="请输入底线库存"></el-input>-->
                            <el-input  v-model="item.stockLimit" oninput="value=value.replace(/\D/g,'')" placeholder="请输入底线库存"></el-input>
						</el-form-item>
						<el-form-item :label="$t('common.Synchronizetoothertenants')" label-width="120px">

							<el-checkbox-group v-model="curTenantList">
								<el-checkbox v-for="item in tenantList" :key="item.tenant_id" :label="item.tenant_id">
									{{ item.name }}
								</el-checkbox>
							</el-checkbox-group>
						</el-form-item>
					</div>
				</el-form>
				</div>
				<span slot="footer" class="dialog-footer">
					<el-button @click="closeStockLimitDialog">{{$t('common.Cancel')}}</el-button>
					<el-button type="primary" :loading="fullscreenLoading" @click="saveStockLimit">{{$t('common.Confirm')}}</el-button>
   			   </span>
  </el-dialog>

		<el-dialog title="" :visible.sync="dialogfixedPriceVisible" v-if="dialogfixedPriceVisible" width="30%"
			append-to-body :before-close="fixedPriceClose">
			<div>
				<el-form ref="titleFrom" label-width="100px">
					<el-form-item v-for="item in formData.prices" :key="item.countryCode"
						:label="$dict.getDictValue('countrys', item.countryCode)">
						<el-input v-model="item.fixedPrice" @input="check_num(item)" @blur="check_blurNum(item)"
							:placeholder="$t('common.PleaseEnterThePrice')"></el-input>
					</el-form-item>
				</el-form>

				<span slot="footer" class="dialog-footer">
					<el-button @click="fixedPriceClose"> {{ $i18n.t("common.cancel") }}</el-button>
					<el-button type="primary" :loading="fixedPriceLoading" @click="postFixedPrice"> {{ $i18n.t("common.confirm") }}</el-button>
				</span>
			</div>
		</el-dialog>


		<el-dialog :title='$i18n.t("common.prompt")' :visible.sync="dialogLowestPriceVisible" width="20%" append-to-body
			:before-close="handleLowestPriceClose">
			<div v-if="dialogLowestPriceVisible">
				<h4><span>{{$t('common.FixedPriceCannotBeLowerThanTheLowestPrice')}}：</span></h4>
				<div style="width: 100%; display: flex; justify-content: center; align-items: center">
					<div style="width: 80%">
						<div v-for="(item, index) in checkLowestPrice" :key="index" style="margin: 10px 10px">
							{{ $dict.getDictValue('countrys', item.countryCode) }}:<span style="color: red">{{
								item.price.toFixed(2) }}</span>
						</div>
					</div>
				</div>

				<span slot="footer" class="dialog-footer">
					<el-button @click="updateLowestPrice">{{ $i18n.t("common.confirm")}}</el-button>
				</span>
			</div>
		</el-dialog>

     

</div>

</template>
<script>
import Config from '@/utils/config';
import Cache from '@/utils/cache';
import scene from '../../utils/scene';

export default {
props: {
  //编辑列表
  special: {
    type: Array,
    default: () => {
      return []
    }
  },
  formListQuery: {
    type: Object,
    default: () => {
      return {}
    }
  },
  formData: {
    type: Object,
    default: () => {
      return {}
    }
  },
  //默认字典数据
  defaultFormData: {
    type: Object,
    default: () => {
      return {}
    }
  },
  //字典
  dictionary: {
    type: Object,
    default: () => {
      return {}
    }
  },
  //默认字典数据
  defaultDictionary: {
    type: Object,
    default: () => {
      return {}
    }
  },
  treeData: {
    //多选数据
    type: Array,
    default: () => {
      return []
    }
  }
},
data() {
  return {
    currentTenant:Cache.get(Config.currentTenant).tenant_id,
    tenant_idMall:Config.tenant_idMall,//商城租户id
    fixedPriceLoading: false,
		fullscreenLoading: false,
    isStockLimit: false,
    dialogfixedPriceVisible: false,
    dialogLowestPriceVisible: false,
    dialogStockLimitVisible:false,
    maxlength: 13,
    formDataSku: "",
    active_Name: '1',
    bannerCountriesList: [],
    checkLowestPrice: [],//校验最低价格
    options: [],
    checkedKeys: [],
    tenantList: [],
    curTenantList: [],
    goodsSpecials: [],
    defaultProps: {
      label: 'title',
      id: 'goodsClassifyId',
      children: 'children',
      disabled: function (data, node) {
        if (data.parentId == 0 && data.children && data.children.length>0) {
          return true
        } else {
          return false
        }
      }
    }
  }
},
computed: {
	scene() {
		return scene
	}
},
methods: {
  initData() {
    this.getCurTenantList();
    if (!this.formData.sku) {
      // 早期版本 保存详情页 可以更新sku，数量装版本之后不需要保存更新sku ,并且容易被误导，运营点击详情看有sku，不保存的话不能上架
      // this.getDistributionSku()
    }
    if (this.formData.treeList && this.formData.treeList.length > 0) {
      let arr = []
      this.formData.treeList.forEach((item, index) => {
        arr.push(item.goodsClassifyId)
      })
      this.checkedKeys = arr
    }
		this.getGoodsSpecials();
  },
  getDistributionSku() {
    this.$store.dispatch('getDistributionSku', { 'historyGoodsId': this.formData.historyId }).then((res) => {
      if (res && res.data.length > 0 && this.formData.SKUES.length > 0) {
        res.data.forEach((item, index) => {
          this.formData.SKUES.forEach((item2, index2) => {
            if (item2.countryCode == "ES") {
              if (item.PackageQuantity == item2.saleDoubleEbp && item.Ean == this.formData.barCode) {
                this.formData.sku = item.SKU
              }
            }
          })
        })
      }
    });
  },
  handleInput(e) {
    console.log('this.formListQuery.peso', this.formListQuery.peso)
    let value = e.replace(/[^\d.]/g, "").replace(/\.{2,}/g, ".").replace(/^\D*([0-9]\d*\.?\d{0,5})?.*$/, '$1');
    this.formListQuery.peso = value
  },
  append(data) {
    const newChild = { id: id++, label: 'testtest', children: [] };
    if (!data.children) {
      this.$set(data, 'children', []);
    }
    data.children.push(newChild);
  },
  remove(node, data) {
    const parent = node.parent;
    const children = parent.data.children || parent.data;
    const index = children.findIndex(d => d.id === data.id);
    children.splice(index, 1);
  },
  //关闭窗口
  closeDialog() {
    this.$emit("closeDialog")
  },
  handleSearchList() {
    this.$emit("handleSearchList")
  },
  renderContent(h, { node, data, store }) {
    return (
      <span class="custom-tree-node">
        <span>{node.label}</span>
        <span style="margin-left: 100px;">
          <el-button size="mini" type="text" icon="el-icon-plus" on-click={() => this.append(data)}></el-button>
          <el-button size="mini" type="text" icon="el-icon-minus" on-click={() => this.remove(node, data)}></el-button>
        </span>
      </span>
    );
  },
  handleActiveClick(val) {
    this.active_Name = val.name
  },
  //打开编辑商品
  openFixedPrice() {
    this.dialogfixedPriceVisible = true;
  },
  //关闭编辑商品
  fixedPriceClose() {
    this.dialogfixedPriceVisible = false;
    this.formData = JSON.parse(JSON.stringify(this.defaultFormData))
  },
  handleLowestPriceClose(){
    this.dialogLowestPriceVisible = false;
  },
  //限制
  check_num(val) {
    console.log('e1', val.fixedPrice)
    //禁止录入整数部分两位以上，但首位为0
    val.fixedPrice = val.fixedPrice.replace(/^([1-9]\d*(\.[\d]{0,2})?|0(\.[\d]{0,2})?)[\d.]*/g, '$1');
    //禁止录入整数部分超过6位
    val.fixedPrice = val.fixedPrice.replace(/^([1-9]\d{5})\d*/g, '$1');
    //替换除了数字和.以外的字符
    val.fixedPrice = val.fixedPrice.replace(/[^\d\.]/g, '');
    //第一个输入.  则自动变为0.
    val.fixedPrice = val.fixedPrice.replace(/^\./g, '0.');
    //保证.只出现一次，而不能出现两次以上 2..    2.2.2
    val.fixedPrice = val.fixedPrice.replace('.', '$#$').replace(/\./g, '').replace('$#$', '.');
    //只能输入两个小数
    val.fixedPrice = val.fixedPrice.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3');
  },
  check_blurNum(val) {
    // if (val.fixedPrice == 0) {
    // 	val.fixedPrice = null
    // }
  },
  //更新固定价格为最低价
  updateLowestPrice(){
    this.formData.prices.forEach(item=>{
      this.checkLowestPrice.forEach(item2=>{
        if(item.countryCode==item2.countryCode && item2.price){
          item.fixedPrice=item2.price.toFixed(2)
        }
      })
    })
    this.dialogLowestPriceVisible = false;
  },
  //编辑固定价格
  postFixedPrice() {
    this.fixedPriceLoading = true;
    let checkPrice = []
    this.formData.prices.forEach(item => {
      if (item.fixedPrice) {
        checkPrice.push(
          {
            price: item.fixedPrice,
            countryCode: item.countryCode
          }
        )
      }
    });
    let checkQuery = {
      goodsId: this.formData.goodsId,
      priceList: checkPrice
    }
    this.$store.dispatch('postCheckLowestPrice', checkQuery).then((resCheck) => {
      if (resCheck) {
        //校验最低价
        if (resCheck.result == false) {
          this.fixedPriceLoading = false;
          this.checkLowestPrice = resCheck.priceList
          if (this.checkLowestPrice && this.checkLowestPrice.length > 0) {
            this.dialogLowestPriceVisible = true
          }
          return
        }
        this.$store
          .dispatch("putFixedPrice", {
            goodsIdList: [this.formData.goodsId],
            fixedPriceDtoList: this.formData.prices
          }).then(res => {
            this.$message({
              message: this.$i18n.t("common.SubmittedSuccessfully"),
              type: 'success',
              duration: 1000
            });
            this.closeDialog()
            this.handleSearchList()
            this.$emit("handleResetSearch2")
            this.fixedPriceLoading = false;
          }).catch(err => {
            this.fixedPriceLoading = false;
          });
      }
    }).catch(err => {
      this.fixedPriceLoading = false;
    });
  },
  //提交
  onSubmit() {
    this.$confirm(this.$i18n.t("common.Whethertosubmitdata"), this.$i18n.t("common.Prompt"), {
      confirmButtonText: this.$i18n.t("common.confirm1"),
      cancelButtonText: this.$i18n.t("common.Cancel"),
      type: 'warning'
    }).then(() => {
      if (this.active_Name == 4) {
        let stockLimitConfigList = [];
        stockLimitConfigList = this.goodsSpecials.map((item) => {
          return {
            stockLimit: item.stockLimit ? item.stockLimit : 0,
            countryCode: item.countryCode
          }
        })
        this.$store
          .dispatch("putBatchUpdate", {
            stockLimitConfigList,
            tenantList: this.curTenantList,
            goodsIdList: [this.formData.goodsId]
          }).then(res => {
            this.$message({
              message: this.$i18n.t("common.SubmittedSuccessfully"),
              type: 'success',
              duration: 1000
            });
            this.closeDialog()
            this.handleSearchList()
            this.$emit("handleResetSearch2")
          });
      } else {
        this.formListQuery.classifyIds = this.$refs.tree.getCheckedKeys()
        let countryCodes = new Set(this.formListQuery.countryCodes)
				//校验上架国家
				if (!this.validShelfCountry(this.formData, countryCodes)) {
					this.$message({
						message: this.$i18n.t("common.SalesMultipleDoesNotMatchCannotBeListed"),
						type: "warning"
					})
					return;
				}

        if (this.formListQuery.peso > 1000) this.formListQuery.peso = 1000; if (this.formListQuery.peso < 0) this.formListQuery.peso = 0; if (this.formListQuery.peso.length > 13) this.formListQuery.peso = this.formListQuery.peso.slice(0, 13)
        this.$store
          .dispatch("putGoodsbjSpecial", {
            goodsId: this.formData.goodsId,
            PESO: this.formListQuery.peso,
            classifyIds: this.formListQuery.classifyIds,
            countryCodes: countryCodes,
            sku: this.formData.sku ? this.formData.sku : ''
          }).then(res => {
            this.$message({
              message: this.$i18n.t("common.SubmittedSuccessfully"),
              type: 'success',
              duration: 1000
            });
            this.closeDialog()
            this.handleSearchList()
            this.$emit("handleResetSearch2")
          });
      }
    });
  },
	//打开库存底线弹窗
	openStockLimitDialog() {
		this.getCurTenantList();
		this.getGoodsSpecials();
		this.dialogStockLimitVisible = true;
	},
  //关闭库存底线弹窗
  closeStockLimitDialog(){
    this.dialogStockLimitVisible=false
  },
	getCurTenantList() {
		this.$store.dispatch('getbyUser', {}).then(res => {
			this.tenantList = []
			this.curTenantList = []
			res.forEach(element => {
				if (element.app_id == Config.appId && element.permission && element.tenant_id != Cache.get(Config.currentTenant).tenant_id
				&& element.tenant_id != Config.AigoSmartNonSelf) {
					this.tenantList.push(element);
					//this.curTenantList.push(element.tenant_id)

				}
			});
		});
	},
	getGoodsSpecials() {
		if (this.formData.goodsSpecials && this.formData.goodsSpecials.length > 0) {
			this.goodsSpecials = JSON.parse(JSON.stringify(this.formData.goodsSpecials))
		}
	},
  //编辑库存底线
  saveStockLimit(){
    let stockLimitConfigList=[];
    let goodsIdList=[];
		let tenantList=[];
		if (scene.isNonSelf) {
			stockLimitConfigList=this.goodsSpecials.map((item)=>{
				return {
					ecomRemainQty:item.ecomRemainQty?Number(item.ecomRemainQty):0,
					stockLimit:item.stockLimit?item.stockLimit:0,
					countryCode:item.countryCode
				};
			})
		} else {
			stockLimitConfigList=this.goodsSpecials.map((item)=>{
				return {
					stockLimit:item.stockLimit?item.stockLimit:0,
					countryCode:item.countryCode
				}
			})
			tenantList = this.curTenantList;
		}

		goodsIdList.push(this.formData.goodsId)
		this.fullscreenLoading = true;
    this.$store
        .dispatch("putBatchUpdate",{
          stockLimitConfigList,
          tenantList:tenantList,
          goodsIdList
        }).then(res => {
      this.$message({
        message: this.$i18n.t("common.SubmittedSuccessfully"),
        type: 'success',
        duration: 1000
      });
      this.dialogStockLimitVisible=false
			this.closeDialog()
			this.handleSearchList()
			this.$emit("handleResetSearch2")
			this.fullscreenLoading = false
    }).catch(err => {
			this.fullscreenLoading = false
		});
  },
	//校验上架国家
	validShelfCountry(goodsPrivately, countryCodes) {
    if(countryCodes.size < 1){
      return true;
    }
		//校验上架国家
    // 获取 选中的上架国家 并且 销售倍数不为空 为null代表 对应国家没有相关价格数据
		let shelfGoodsSpecials = goodsPrivately.goodsSpecials.filter((item)=> item.saleDoubleEbpNew).filter(item => countryCodes.has(item.countryCode));
		let packageQuantity = goodsPrivately.packageQuantity;
		if (!shelfGoodsSpecials || !packageQuantity || shelfGoodsSpecials.length != countryCodes.size) {
			return false;
		}

    // 各国统一参考ES的销售倍数作为判断标准 只要包装数符合西班牙的销售倍数的整数倍就可以上架
    let shelfGoodsSpecialsByEs = goodsPrivately.goodsSpecials.filter(item => item.countryCode == 'ES');
		if (!shelfGoodsSpecialsByEs) {
      // 没有西班牙数据
			return false;
		}
		let bo = true;
		// shelfGoodsSpecials.forEach(item => {
    shelfGoodsSpecialsByEs.forEach(item => {
			if (!item.saleDoubleEbpNew || (packageQuantity % item.saleDoubleEbpNew) !== 0) {
				bo = false;
				return;
			}
		})
		return bo;
	},
	tableInputBlur(row) {
		for (let i = 0; i < this.goodsSpecials.length; i++) {
				const old = this.goodsSpecials[i];
				if (old.countryCode == row.countryCode) {
					this.goodsSpecials[i] = row;
				}
		}

		console.log("this.goodsSpecials",this.goodsSpecials)
	}
},
mounted() {
  this.initData()
}
};
</script>
<style scoped>
.input-width {
width: 70%;
}

.dialog-footer {
margin-top: 20px;
display: flex;
justify-content: center;
align-items: center;
}

.custom-tree-node {
flex: 1;
display: flex;
align-items: center;
justify-content: space-between;
font-size: 14px;
padding-right: 8px;
}

.from-item {
font-size: 14px;
line-height: 28px;
padding-bottom: 8px;
}

/deep/.descriptions .el-descriptions-item {
font-size: 14px;
line-height: 28px;
}

/deep/.descriptions .el-descriptions-item__label {
display: flex;
align-items: center;
}

/deep/ .inputNumber input::-webkit-outer-spin-button,
/deep/ .inputNumber input::-webkit-inner-spin-button {
-webkit-appearance: none;
}

/deep/ .inputNumber input[type="number"] {
-moz-appearance: textfield;
}
.stock-table .el-input /deep/ .el-input__inner {
	text-align: center;
}
</style>