<template> 
	<div>
		<filter-container ref='formTableDialog' :arg="argFrom" :placeholder="placeholderFrom" :dictionary="dictionary" :isShowObject="isShowObjectFrom" @handleExport='handleExport' @handleSearchList='handleSearchList' @handleResetSearch='handleResetSearch' @handleChangeCategory="handleChangeCategory"></filter-container>
		<slot name="tableSlot"></slot>
		<span v-if="closeFooter==false" slot="footer" style="display: flex;justify-content: flex-end;align-items: center;">
			<span v-if="typeName != '分销'">{{ $t('common.Selected')}} : <span style="color: #008000;font-size: 16px;font-weight: 800;">{{pageDataSelectList.length}}</span></span>
			<el-button style="width: 20%;margin-left: 50px;" type="primary" @click="handleSelected">{{$i18n.t("common.confirm")}}
			</el-button>
		</span>
	</div>
</template>
<script>
	import FilterContainer from '@/components/common/FilterContainer.vue';
	export default {
		components: {
			FilterContainer
		},
		props: {
			argFrom: {
				type: Object,
				default: () => {
					return {}
				}
			},
			dictionary:{
				type: Object,
				default: () => {
					return {}
				}
			},
			placeholderFrom: {
				type: Object,
				default: () => {
					return {}
				}
			},
			closeFooter: {
				type: Boolean,
				default: false
			},
			isShowObjectFrom:{
				type: Object,
				default: () => {
					return {}
				}
			},
			//数量
			pageDataSelectList:{
				type: Array,
				default:  () => {
					return []
				}
			},
			typeName: {
				type: String,
				default: ''
			}
		},
		data() {
			return {
				formLabelWidth: '120px',
				query: {},		
			};
		},
		methods: {		
			handleExport(Data){
                this.query= Data
				this.$emit('handleExport',this.query)
			},
			handleSearchList(Data){
				this.query= Data
				this.$emit('handleSearchList',this.query)
			},
			handleResetSearch(){
				this.$emit('handleResetSearch')
			},
			handleChangeCategory(val){
				this.$emit('handleChangeCategory',val)
			},	
			//提交
			handleSelected(){
				this.$emit('handleSelected')
			},
            getAndSetQueryData(){
                this.query = this.$refs.formTableDialog.listQueryData
                return this.query
            }
		},
		mounted(){

		}
	};
</script>
<style scoped>
	.input-width {
		width: 70%;
	}

	.dialog-footer {
		display: flex;
		justify-content: center;
		align-items: center;
	}
</style>
